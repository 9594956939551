<template>
  <div :class="['input-color']">
    <v-swatches
      v-model="setValue[id]"
      show-fallback
      fallback-input-type="color"
      popover-x="right"
    ></v-swatches>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="input-color__error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
export default {
  name: "InputColor",
  components: {
    VSwatches,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    updateColor(eventData) {
      console.log(eventData);
      this.setValue[id] = eventData;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-color {
  &__error {
    position: absolute;
    color: red;
    padding-left: 10px;
    font-size: 12px;
  }
}
</style>
